import { useState,useEffect } from "react"
import { Box,Stack,Typography } from "@mui/material"
import SideBar from "./SideBar"
import Videos from "./Videos"
import { fetchFromAPI } from "../utils/fetchFromAPI"
import Loader from "./Loader"


export default function Feed() {

  const [selectedCategory, setSelectedCategory] = useState("New")
  const [videos, setVideos] = useState([])
 

  useEffect(() => {
    fetchFromAPI(`search?part=snippet&q=${selectedCategory}`).then((data) => {
      setVideos(data.items)
    })
    
  },[selectedCategory])

  if(!videos.length) return <Loader />;

    return (
    <Stack sx={{ flexDirection: { sx: "column", md: "row" }}}>
      <Box sx={{ height: { sx: "auto", md: "92vh" }, borderRight: "1px solid #fff", px: { sx: 0, md: 2 }}}>


        <SideBar selectedCategory={selectedCategory} setSelectedCategory={setSelectedCategory}/>

        <Typography className="copyright" variant="overline" sx={{ mt: 1.5, color: "#fff" }}>
          Copyright © 2023 Mahmoud Tarek
        </Typography>
      </Box>

      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Typography variant="h4" fontWeight={"bold"} mb={2} sx={{ color: "#fff" }}>
          {selectedCategory}<span style={{ color: "#f31503" }}> Videos</span>
        </Typography>



        <Videos videos={videos}/>
      </Box>

    </Stack>)
  
}
